import React from "react";
import { ICallingDisposition } from "@vahak/core/dist/custom-types";
import Flex from "@vahak/core-ui/dist/layout/Flex";
import BordererCard from "@vahak/core-ui/dist/components/common/BordererCard/BordererCard";
import { generateSelectOption } from "../../../../methods/uiUtil";
import Typography from "@vahak/core-ui/dist/components/Typography";
import DotSeparatedList from "@vahak/core-ui/dist/components/common/DotSeparatedList/DotSeparatedList";
import COLORS from "@vahak/core-ui/dist/constants/colors";
import { formatDate } from "@vahak/core-ui/dist/methods";
import PostingChip from "../../../posting-form/common/posting-chip/PostingChip";

interface DispositionCardProps {
    data?: ICallingDisposition;
}

const DispositionCard = ({ data }: DispositionCardProps) => {
    const list = [
        generateSelectOption("Status", data?.status),
        generateSelectOption("Disposition", data?.disposition),
        generateSelectOption("Sub-Disposition", data?.sub_disposition),
        generateSelectOption("Closing", data?.closing)
    ];
    return (
        <BordererCard width={1000}>
            <Flex flexDirection="column" gap={16}>
                <Typography weight="medium" color={COLORS.GREY_600}>
                    <DotSeparatedList
                        items={[
                            {
                                node: <Typography>Updated By {data?.created_by_name}</Typography>,
                                id: "created_by_name"
                            },
                            {
                                node: (
                                    <Typography>
                                        {formatDate(new Date(data?.created_at ?? "-"), "MMMM DD, YYYY at h:mm a")}
                                    </Typography>
                                ),
                                id: "created_at"
                            }
                        ]}
                    />
                </Typography>
                <Flex gap={8}>
                    {list
                        ?.filter((val) => !!val?.value)
                        .map((item, ix) => (
                            <PostingChip key={item?.label} weight="medium">
                                {item?.value}
                            </PostingChip>
                        ))}
                </Flex>
                <Typography weight="medium" color={COLORS.GREY_600}>
                    {data?.other}
                </Typography>
            </Flex>
        </BordererCard>
    );
};

export default DispositionCard;
