import React from "react";
import ShowMoreText from "@vahak/core-ui/dist/components/ShowMoreText";
import Typography from "@vahak/core-ui/dist/components/Typography";

import COLORS from "@vahak/core-ui/dist/constants/colors";
import { filteredArray } from "@vahak/core-ui/dist/methods/array";
import { ICallingDisposition } from "@vahak/core/dist/custom-types/common-preloads";
import DispositionCard from "./DispositionView/DispositionCard";

interface CallingDispositionViewProps {
    callingDisposition?: ICallingDisposition;
    limit?: number;
}

const CallingDispositionView = ({ callingDisposition, limit = 140 }: CallingDispositionViewProps) => {
    if (!callingDisposition) {
        return null;
    }

    const mergedText = filteredArray(
        [
            callingDisposition.status,
            callingDisposition.disposition,
            callingDisposition.sub_disposition,
            callingDisposition.closing,
            callingDisposition.other
        ],
        true
    ).join(", ");

    return (
        <div>
            <Typography size="s" lineHeight={1.5} color={COLORS.GREY_900} weight="medium">
                Disposition : &nbsp;
                <ShowMoreText
                    text={mergedText}
                    limit={limit}
                    showPopUpView={true}
                    more={"see more"}
                    truncatedEnding=""
                    renderPopUpBody={<DispositionCard data={callingDisposition} />}
                    popUpProps={{
                        titleText: "Disposition",
                        modalSize: "fit"
                    }}
                    actionAlwaysVisible
                />
            </Typography>
        </div>
    );
};

export default CallingDispositionView;
