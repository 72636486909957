import React, { useMemo } from "react";
import Styles from "./Lorry.module.scss";

// Components
import AppImg from "../../utils/AppImg";
import Typography from "@vahak/core-ui/dist/components/Typography";
import Flex from "@vahak/core-ui/dist/layout/Flex";
import COLORS from "@vahak/core-ui/dist/constants/colors";
import IconWrapper from "@vahak/core-ui/dist/components/IconWrapper";

// Assets
import VerifiedTick from "@vahak/core/dist/icons/verified-tick.svg";

// methods
import { filteredArray } from "@vahak/core-ui/dist/methods/array";
import { formatLorryNum } from "@vahak/core/dist/methods/formatLorryNum";
// misc
import {
    LORRY_BODY_TYPE,
    LORRY_BODY_TYPE_MAP,
    LORRY_CATEGORIES_ENUM,
    getLorryDetailsByType
} from "@vahak/core/dist/constants";

interface LorryProps {
    id?: string;
    lorryId?: number;
    lorryType: LORRY_CATEGORIES_ENUM;
    bodyType?: LORRY_BODY_TYPE;
    lorryModel?: string;
    lorryNumber?: string;
    isVerified?: boolean;
}

const Lorry = ({ id = "", ...data }: LorryProps) => {
    const lorry = getLorryDetailsByType(data?.lorryType);

    const lorryBodyInfo = useMemo(
        () => filteredArray([LORRY_BODY_TYPE_MAP[data?.bodyType!], lorry?.label]).join(", "),
        [data?.bodyType, lorry?.label]
    );
    return (
        <div className={Styles.main}>
            <div className={Styles.icon}>
                {!!lorry?.iconLeft && <AppImg width={76} height={60} src={lorry?.iconRightV2} />}
            </div>
            <Flex className={Styles.content} justifyContent="space-between" alignItems="center">
                <Typography weight="semibold" size="s" lineHeight={1}>
                    <Flex className={Styles.info} flexDirection="column" alignItems="flex-start" gap={8}>
                        <Typography lineHeight={1} color={COLORS.BLUE_1000} id={`${id}-lorry-body-info`}>
                            {lorryBodyInfo}
                        </Typography>
                        {!data?.lorryNumber ? (
                            <Typography color={COLORS.ORANGE} size="xs" id={`${id}-lorry-number`}>
                                Lorry not attached
                            </Typography>
                        ) : (
                            <Typography lineHeight={1} size="xs" color={COLORS.GREY_700} textTransform="uppercase">
                                <Typography id={`${id}-lorry-number`} data-lorry-number={data?.lorryNumber}>
                                    {formatLorryNum(data?.lorryNumber)} &nbsp;
                                </Typography>
                                <VerifiedTick id={`${id}-lorry-verified`} />
                            </Typography>
                        )}
                        <IconWrapper gap={"5px"}>
                            <Typography
                                size="xs"
                                color={COLORS.GREY_500}
                                id={`${id}-lorry-model`}
                                data-one-line-text
                                title={data?.lorryModel}
                            >
                                {data?.lorryModel}
                            </Typography>
                        </IconWrapper>
                    </Flex>
                </Typography>
            </Flex>
        </div>
    );
};

export default Lorry;
