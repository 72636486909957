import { useToggleModalWithLocalState } from "@vahak/core-ui/dist/hooks";
import UpdateRemarks, { RemarksSubmitParams } from "../components/admin/UpdateRemarks";
import { ComponentProps } from "react";
import { UpdateRemarksPayload, useUpdateRemarks } from "../services/remarks/useUpdateRemarks";
import { getSourceScreen } from "../components/admin/ticket-system-helper/method";
import { useRouter } from "next/router";
import { useQueryClient } from "react-query";
import { toast } from "@vahak/core/dist/components/toast/toast";

interface UseUpdateDispositionProps {
    type: ComponentProps<typeof UpdateRemarks>["type"];
    payload?: Partial<UpdateRemarksPayload>;
    successQueries?: string[];
    successMsg?: string;
}

const modalTitle = {
    bid: "Bid Remarks",
    "live-lorries": "Lorry Remarks",
    "tap-n-go-load": "Load Remarks"
};

const useUpdateDisposition = ({ type, payload, successQueries, successMsg }: UseUpdateDispositionProps) => {
    const { toggleModal, isModalOpen } = useToggleModalWithLocalState(false);
    const route = useRouter();
    const queryClient = useQueryClient();
    const { mutateAsync: updateRemarks } = useUpdateRemarks({
        onSuccess: () => {
            successMsg && toast.success("Updated!!");
            toggleModal();
        }
    });

    const getBidSourceScreen = () => {
        if (route?.query?.loadCategory) return route.query.loadCategory as string;
        return getSourceScreen(route.pathname);
    };

    const handleRemarksUpdate = async (data: RemarksSubmitParams) => {
        const finalData: Partial<UpdateRemarksPayload> = {
            ...payload,
            disposition: data.disposition,
            sub_disposition: data.subDisposition,
            other: data.comment,
            source: getBidSourceScreen()
        };

        await updateRemarks(finalData as UpdateRemarksPayload);
        successQueries && queryClient.invalidateQueries(successQueries);
    };

    const modalSlot = isModalOpen ? (
        <UpdateRemarks
            isOpen={isModalOpen}
            onToggle={toggleModal}
            titleText={modalTitle[type]}
            type={type}
            onSubmit={handleRemarksUpdate}
        />
    ) : null;

    return {
        modalSlot,
        toggleModal
    };
};

export default useUpdateDisposition;
